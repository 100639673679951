


body{
  overflow-x: hidden   !important;
  /* overflow-y: hidden;  */
  /* overflow: hidden; */
padding: 0%;
margin: 0%;
font-family: 'Roboto', sans-serif;
-webkit-box-sizing: border-box;
      box-sizing: border-box;
padding-top: 70px; /* This should match the height of the .custom-navbar */

}
.logoimage{
width: 90px !important;
}




.card-images {
width: 100%;
height: auto;
}







.textes {
font-size: 18px;
}

.slider-item-container {
position: relative;
}

.slider-item {
position: relative;
}

.slider-item img {
width: 100%;
height: auto;
}

.text-overlay h5,
.text-overlay p,
.text-overlay button {
margin: 10px 0;
}


.TEXT{
font-size: 20px !important;
font-weight: 600;
line-height: 60px !important;
}

p{
color: gray;
font-weight: 400;
}

.shadow-sms{
-webkit-box-shadow: 1px 0px 10px 0px #4e97cf;
        box-shadow: 1px 0px 10px 0px #4e97cf;
  /* transition:  0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s; */
  margin: 130px 100px 130px 100px;
  --e-column-margin-right: 100px;
  --e-column-margin-left: 100px;

  padding: 100px 100px 100px 100px;
}
.form-controls{
/* background-color: #f5e1da !important; */
padding: 5px !important;
/* border: none !important; */
outline: none !important;
width: 100%;
}
.img-fluid{
height: 300px !important;
}

.img-fluids{
height: 90px !important;
}

.btns{

border-radius: 6px;
text-decoration: none;
width: 97px;
height: 36px;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: center;
    -ms-flex-pack: center;
        justify-content: center;
}





.nav-link{
color: #000000 !important;
font-weight: 400 !important;
font-size: 17px !important;

}
.nav-link .active{
font-weight: bold !important;
border-bottom: 2px solid #007bff !important;
}
.colors{
color: #016fd1 !important;
}
p{
text-align: justify !important;
}
.color{
color: #781e85;
}
.review{
color: #016fd1;
font-weight: 400;
font-size:35px;
}
.reviews{
color: #016fd1;
font-weight: bold;
font-size:35px;
}
.custom-shadow {
-webkit-box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;  padding: 15px;
border-radius: 5px;
background-color: #fff;
-webkit-transition: -webkit-box-shadow 0.3s ease;
transition: -webkit-box-shadow 0.3s ease;
-o-transition: box-shadow 0.3s ease;
transition: box-shadow 0.3s ease;
transition: box-shadow 0.3s ease, -webkit-box-shadow 0.3s ease;
width: 280px !important;
}
.custom-shadows {
padding: 20px;
/* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
margin-bottom: 20px; /* Ensure there is space below each column */
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
    -ms-flex-direction: column;
        flex-direction: column;
width: 500px !important;
-webkit-box-pack: center;
    -ms-flex-pack: center;
        justify-content: center;
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;

}
.custom-shadowes{
padding: 20px !important;
/* width: 410px !important; */

margin-bottom: 20px !important; /* Ensure there is space below each column */
display: -webkit-box !important;
display: -ms-flexbox !important;
display: flex !important;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
    -ms-flex-direction: column;
        flex-direction: column;
/* width: 500px !important; */
-webkit-box-pack: center;
    -ms-flex-pack: center;
        justify-content: center;
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;

}

.img-border{
border-radius: 50% !important;
}
.testimonial-slide {
text-align: center;
padding: 20px;
}

.img-border {
border-radius: 50%;
margin-bottom: 20px;
}

p {
font-size: 16px;
margin-bottom: 10px;
.testimonial-slide {
  text-align: center;
  padding: 20px;
}

.img-border {
  border-radius: 50%;
  margin-bottom: 20px;
}

.p-test{
  font-size: 16px;
  margin-bottom: 10px;
  color: #000000 !important;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  line-height: 2em;
}

.h4-test{
  margin-bottom: 5px;
  color: #000000;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  font-style: normal;
}

.span-test{
  color: grey !important;
  font-size: 15px;
  font-weight: 300;
  text-transform: uppercase;
  font-style: normal;
  line-height: 0.9em;
  
}

}

h4 {
font-size: 20px;
font-weight: bold;
margin-bottom: 5px;
}

span {
font-size: 14px;
color: #555;
}

.custom-shadows{
padding: 20px;
-webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
margin-bottom: 20px; /* Ensure there is space below each column */
}
.img-container {
overflow: hidden; /* Ensures the image doesn't overflow the container */
width: 100%; /* Ensures the container takes up the full width of its parent */
}


.btn-primarys{
font-family: "Montserrat", Sans-serif;
  fill: #ffffff !important;
  color: #ffffff !important;
  background-color: #016fd1 !important;
  border-style: solid !important;
  border-width: 1px 1px 1px 1px !important;
  border-color: #ffffff !important;
  border-radius: 50px 50px 50px 50px !important;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
width: 150px !important;
font-weight: 500;
-webkit-transition: -webkit-transform 0.5s ease !important;
transition: -webkit-transform 0.5s ease !important;
-o-transition: transform 0.5s ease !important;
transition: transform 0.5s ease !important;
transition: transform 0.5s ease, -webkit-transform 0.5s ease !important;
}
.btn-primary{
  font-family: "Montserrat", Sans-serif;
    fill: #ffffff !important;
    color: #ffffff !important;
    background-color: #016fd1 !important;
    border-style: solid !important;
    border-width: 1px 1px 1px 1px !important;
    border-color: #ffffff !important;
    border-radius: 50px 50px 50px 50px !important;
    -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  font-weight: 500;
  -webkit-transition: -webkit-transform 0.5s ease !important;
  transition: -webkit-transform 0.5s ease !important;
  -o-transition: transform 0.5s ease !important;
  transition: transform 0.5s ease !important;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease !important;
  }
 
 

  .testimonial-card {
    padding: 20px;
    -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
    /* margin-bottom: 20px; */
    -webkit-transition: -webkit-box-shadow 0.3s ease-in-out;
    transition: -webkit-box-shadow 0.3s ease-in-out;
    -o-transition: box-shadow 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
    width: 500px !important;
    
  /* margin: 0px 16px 0px 0px; */
  }
  
  .testimonial-card:hover {
    -webkit-box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px !important;
            box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px !important;
  }
  
  .test-img {
    width: 80px; /* Adjust the size as needed */
    height: 80px; /* Adjust the size as needed */
    border-radius: 50%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  
  
  
.btn-primarys:hover{
  background-color: #fff !important;
  color: #000000 !important;
  font-weight: 500;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  -webkit-transition: -webkit-transform 0.5s ease !important;
  transition: -webkit-transform 0.5s ease !important;
  -o-transition: transform 0.5s ease !important;
  transition: transform 0.5s ease !important;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease !important;
}
.btn-primary:hover{
  background-color: #fff !important;
  color: #000000 !important;
  font-weight: 500;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  -webkit-transition: -webkit-transform 0.5s ease !important;
  transition: -webkit-transform 0.5s ease !important;
  -o-transition: transform 0.5s ease !important;
  transition: transform 0.5s ease !important;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease !important;
}
.ImgSuper{
width: 100%;
}
.nav-link:hover,
.nav-link:focus {
color: #007bff !important; /* Change color to blue on hover */
}

.nav-link.active {
color: #007bff !important; /* Active link color */
border-bottom: 1px solid #007bff; /* Add border bottom */
}


.img-zooms{
-webkit-transition: -webkit-transform 0.5s ease !important;
transition: -webkit-transform 0.5s ease !important;
-o-transition: transform 0.5s ease !important;
transition: transform 0.5s ease !important;
transition: transform 0.5s ease, -webkit-transform 0.5s ease !important; /* Smooth transition for the zoom effect */  
height: 60px;
}
.img-zoom {
-webkit-transition: -webkit-transform 0.5s ease;
transition: -webkit-transform 0.5s ease;
-o-transition: transform 0.5s ease;
transition: transform 0.5s ease;
transition: transform 0.5s ease, -webkit-transform 0.5s ease;
width: 70%;
height: 60vh;
border-radius: 30%;
}
.font-bolds{
font-weight: 400;
}
.img-zoomes {
-webkit-transition: -webkit-transform 0.5s ease;
transition: -webkit-transform 0.5s ease;
-o-transition: transform 0.5s ease;
transition: transform 0.5s ease;
transition: transform 0.5s ease, -webkit-transform 0.5s ease;
width: 100%;
height: 60vh;

}

.img-zoom:hover {
-webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
        transform: scale(1.1); /* Scales the image to 110% on hover */
}
.img-zoomes:hover {
-webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
        transform: scale(1.1); /* Scales the image to 110% on hover */
}
.img-zooms:hover {
-webkit-transform: scale(1.1) !important;
    -ms-transform: scale(1.1) !important;
        transform: scale(1.1) !important; /* Scales the image to 110% on hover */
color: #4169e1 !important;
  -webkit-filter: brightness(100%) contrast(120%) saturate(100%) blur(0px) hue-rotate(0deg);
          filter: brightness(100%) contrast(120%) saturate(100%) blur(0px) hue-rotate(0deg);
  opacity: 0.7 !important;
  -webkit-transition-duration: 0.5s !important;
       -o-transition-duration: 0.5s !important;
          transition-duration: 0.5s !important; 
}
.custom-shadows:hover {
-webkit-box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px !important;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px !important;
}
.custom-shadowes:hover {
-webkit-box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px !important;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px !important;
}


.custom-shadow:hover {
-webkit-box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px !important;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px !important;
}
.footer {
background-color: #f5e1da;
}

.footer-logo {
max-height: 90px;
}

.footer .text-muted {
font-size: 14px;
background-color: #000 !important;
color: #ffff !important;
border-radius: 50% !important;
padding: 10px !important;
padding: 9px !important;
height: 42px;
width: 42px;
}

#footer {
font-size: 14px;
background: #37517e;
}

#footer .footer-newsletter {
padding: 50px 0;
background: #353839;
text-align: center;
font-size: 15px;
color: #444444;
}

#footer .footer-newsletter h4 {
font-size: 24px;
margin: 0 0 20px 0;
padding: 0;
line-height: 1;
font-weight: 600;
color: #37517e;
}

#footer .footer-newsletter form {
margin-top: 30px;
background: #fff;
padding: 6px 10px;
position: relative;
border-radius: 50px;
-webkit-box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
text-align: left;
}

#footer .footer-newsletter form input[type=email] {
border: 0;
padding: 4px 8px;
width: calc(100% - 100px);
outline:none;
}

#footer .footer-newsletter form input[type=submit] {
position: absolute;
top: 0;
right: 0;
bottom: 0;
border: 0;
background: none;
font-size: 16px;
padding: 0 20px;
background: #47b2e4;
color: #fff;
-webkit-transition: 0.3s;
-o-transition: 0.3s;
transition: 0.3s;
border-radius: 50px;
-webkit-box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type=submit]:hover {
background: #209dd8;
}

#footer .footer-top {
padding: 60px 0 30px 0;
background: #fff;
}

#footer .footer-top .footer-contact {
margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
font-size: 28px;
margin: 0 0 10px 0;
padding: 2px 0 2px 0;
line-height: 1;
text-transform: uppercase;
font-weight: 600;
color: #37517e;
}

#footer .footer-top .footer-contact p {
font-size: 14px;
line-height: 24px;
margin-bottom: 0;
font-family: "Jost", sans-serif;
color: #5e5e5e;
}

#footer .footer-top h4 {
font-size: 16px;
font-weight: bold;
color: #37517e;
position: relative;
padding-bottom: 12px;
}

#footer .footer-top .footer-links {
margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
list-style: none;
padding: 0;
margin: 0;
}

#footer .footer-top .footer-links ul i {
padding-right: 2px;
color: #47b2e4;
font-size: 18px;
line-height: 1;
}

#footer .footer-top .footer-links ul li {
padding: 10px 0;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
padding-top: 0;
}

#footer .footer-top .footer-links ul a {
color: #777777;
-webkit-transition: 0.3s;
-o-transition: 0.3s;
transition: 0.3s;
display: inline-block;
line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
text-decoration: none;
color: #47b2e4;
}

#footer .footer-top .social-links a {
font-size: 18px;
display: inline-block;
background: #47b2e4;
color: #fff;
line-height: 1;
padding: 8px;
margin-right: 4px;
border-radius: 50%;
text-align: center;
width: 36px;
height: 36px;
-webkit-transition: 0.3s;
-o-transition: 0.3s;
transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
background: #209dd8;
color: #fff;
text-decoration: none;
}

#footer .footer-bottom {
padding-top: 30px;
padding-bottom: 30px;
color: #fff;
}

#footer .copyright {
float: left;
}

#footer .credits {
float: right;
font-size: 13px;
}

#footer .credits a {
-webkit-transition: 0.3s;
-o-transition: 0.3s;
transition: 0.3s;
}

.fa-facebook{
font-size: 14px;
background-color: #3b5998 !important;
color: #ffff !important;
border-radius: 50% !important;
padding: 10px !important;
padding: 9px !important;
height: 42px;
width: 42px;
}

.fa-twitter{
font-size: 14px;
background-color: #4169e1 !important;
color: #ffff !important;
border-radius: 50% !important;
padding: 10px !important;
padding: 9px !important;
height: 42px;
width: 42px;
}
.fa-google-plus{
font-size: 14px;
background-color: #dd4b39 !important;
color: #ffff !important;
border-radius: 50% !important;
padding: 10px !important;
padding: 9px !important;
height: 42px;
width: 42px;
}
.footer a {
color: #6c757d;
text-decoration: none;
}


.footer a:hover {
color: #0056b3;
text-decoration: underline;
}

.card {
max-width: 800px;
margin: 0 auto;
}

@media (max-width: 768px) {
.form-row {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.footer-logo {
margin-top: 30px;
}

.form-group {
  margin-bottom: 15px;
}
}

@media (max-width: 992px) {
.navbar-toggler{
  border: none !important;
  
outline: none !important;
}
.footer-logo {
  margin-top: 30px;
    }
.tab.active{
  padding: 3px !important;
}

body{
  overflow-x: hidden;
}

.shadow:hover{
  -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px !important;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px !important;
}


.logo-image{
  width: 90px !important;
}
.img-zoom{
  width: 100%;
}
.App{
  overflow: hidden !important;
}

.TEXT{
  font-size: 20px !important;
  font-weight: 400;
  line-height: 20px !important;
}
.slider-item img {
  width: 100%;
  height: 32vh;
}





.shadow-sms{
padding: 10px 10px 10px 10px !important;
}
.style-img{
  width: 100% !important;
}
.footer-logo {
  margin-top: 65px;
    }

}

@media (max-width: 768px) {
.navbar-toggler{
  border: none !important;
  outline: none !important;
}

.tab.active{
  padding: 3px !important;
}

body{
  overflow-x: hidden;
}


.logo-image{
  width: 90px !important;
}
.img-zoom{
  width: 100%;
}
.App{
  overflow: hidden !important;
}


.TEXT{
  font-size: 20px !important;
  font-weight: 400;
  line-height: 20px !important;
} 
.slider-item img {
  width: 100%;
  height: 32vh;
}




.shadow-sms{
padding: 10px 10px 10px 10px !important;
}
.custom-shadowes{
  display: flex !important;
  margin-bottom: 20px !important;

    padding: 20px !important;
    width: 100% !important;
}
.style-img{
  width: 100% !important;
}
.footer-logo {
  margin-top: 65px;
    }
    .logoimage{
      width: 60px !important;
      }
}
@media (max-width: 576px) {
.navbar-toggler{
  border: none !important;
  outline: none !important;
}

h3{
  text-align: center;
}
.footer-logo {
  margin-top: 65px;
    }
.tab.active{
  padding: 3px !important;
}

body{
  overflow-x: hidden;
}



.logo-image{
  width: 90px !important;
}
.img-zoom{
  width: 100%;
  height: 50vh;
}
.App{
  overflow: hidden !important;
}
.TEXT{
  font-size: 20px !important;
  font-weight: 400;
  line-height: 20px !important;
}
.slider-item img {
  width: 100%;
  height: 32vh;
}




.shadow-sms{
padding: 10px 10px 10px 10px !important;
}
.custom-shadowes{
  display: flex !important;
  margin-bottom: 20px !important;

    padding: 20px !important;
    width: 100% !important;
}
.style-img{
  width: 100% !important;
}
.logoimage{
  width: 60px !important;
  }
  h2{
    text-align: center;
      font-size: 22px;
  }

}
@media (max-width: 400px) {
.navbar-toggler{
  border: none !important;
  outline: none !important;
}

.footer-logo {
  margin-top: 65px;
    }
.tab.active{
  padding: 3px !important;
}

body{
  overflow-x: hidden;
}

h3{
  text-align: center;
}

.logo-image{
  width: 90px !important;
}
.img-zoom{
  width: 100%;
  height: 50vh;
}
.App{
  overflow: hidden !important;
}
.TEXT{
  font-size: 20px !important;
  font-weight: 400;
  line-height: 20px !important;
}
.slider-item img {
  width: 100%;
  height: 32vh;
}





.shadow-sms{
padding: 10px 10px 10px 10px !important;
}
.custom-shadowes{
  display: flex !important;
  margin-bottom: 20px !important;

    padding: 20px !important;
    width: 100% !important;
}
.style-img{
  width: 100% !important;
}
.logoimage{
  width: 60px !important;
  }
  h2{
    text-align: center;
      font-size: 22px;
  }
}
@media (max-width: 300px) {
.navbar-toggler{
  border: none !important;
  outline: none !important;
}


.footer-logo {
  margin-top: 65px;
    }
.tab.active{
  padding: 3px !important;
}

body{
  overflow-x: hidden;
}



.logo-image{
  width: 90px !important;
}
.img-zoom{
  width: 100%;
  height: 50vh;
}
h3{
  text-align: center;
}
.App{
  overflow: hidden !important;
}
.TEXT{
  font-size: 20px !important;
  font-weight: 400;
  line-height: 20px !important;
}
.slider-item img {
  width: 100%;
  height: 32vh;
}




.shadow-sms{
padding: 10px 10px 10px 10px !important;
}
.custom-shadowes{
  display: flex !important;
  margin-bottom: 20px !important;

    padding: 20px !important;
    width: 100% !important;
}
.style-img{
  width: 100% !important;
}
.logoimage{
  width: 60px !important;
  }
  h2{
    text-align: center;
      font-size: 22px;
  }

}


@media (max-width: 270px) {
.navbar-toggler{
border: none !important;
outline: none !important;

}

.tab.active{
padding: 3px !important;
}


body{
overflow-x: hidden;
}
.img-zoom{
width: 100%;
height: 50vh;
}
h2{
  text-align: center;
    font-size: 22px;
}


.logo-image{
width: 90px !important;
}
h3{
  text-align: center;
}

.App{
overflow: hidden !important;
}
.TEXT{
font-size: 20px !important;
font-weight: 400;
line-height: 20px !important;
}
.slider-item img {
width: 100%;
height: 34vh;
}
.footer-logo {
margin-top: 65px;
  }

  .shadow-sms{
    padding: 10px 10px 10px 10px !important;
  }
  .custom-shadowes{
    display: flex !important;
    margin-bottom: 20px !important;
  
      padding: 20px !important;
      width: 100% !important;
  }
  .style-img{
    width: 100% !important;
  }
  .logoimage{
    width: 60px !important;
    }

}